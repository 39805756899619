import { createStore } from "vuex"
 
const store = createStore({
    state() {
        return {
            u_id: '',
            m_id: 8,
            phone: '',
            show:false,
            dialogVisible:false,
        }
    },
    getters:{
        getMid(state){  
            return 8;
            // if(localStorage.getItem('m_id')) return localStorage.getItem('m_id');
            if(sessionStorage.getItem('m_id')) return sessionStorage.getItem('m_id');
            return state.m_id;
        },
        getPhone(state){   
            // if(localStorage.getItem('phone')) return localStorage.getItem('phone');
            if(sessionStorage.getItem('phone')) return sessionStorage.getItem('phone');
            return state.phone;
        },
        getUid(state){  
            // if(localStorage.getItem('u_id')) return localStorage.getItem('u_id');
            if(sessionStorage.getItem('u_id')) return sessionStorage.getItem('u_id');
            return state.u_id;
        },
        getShow(state){  
            if(sessionStorage.getItem('show')=='true'){ 
                return true;
            } 
            return state.show;
        },

    },
    mutations: {
        setMid(state,m_id) {
            state.m_id = m_id
            sessionStorage.setItem('m_id',m_id)
            localStorage.setItem("m_id",m_id); 
        },
        setPhone(state,phone) {
            state.phone = phone
            sessionStorage.setItem('phone',phone)
            localStorage.setItem("phone",phone); 
        },
        setUid(state,u_id) {
            state.u_id = u_id
            sessionStorage.setItem('u_id',u_id)
            localStorage.setItem("u_id",u_id); 
        },
        setShow(state,show) {
            state.show = show
            sessionStorage.setItem('show',show)
        },
        setDialogVisible(state,dialogVisible) {
            state.dialogVisible = dialogVisible 
        }
    }
})

export default store
