<template>
<el-row>
  <el-col :span="24" style="background: #F7F6F6;">
    <div class="main">
        <div class="title">会议动态 </div>
		
            <div class="content" v-if="bool"> 
                <router-link :to="{name:'content', query: {id:val.n_id}}" v-for="val in listNews" :key="val.n_id"> 
                    <div class="header_title" >
                        <h2>{{val.n_title}}</h2>
                        <p> 
                            <span  v-html="val.n_content"></span>... ...  
                        </p>
                        <span>{{  this.format(val.update_time)}}</span>  
                    </div>  
                </router-link>    
            </div>  
    </div>
    </el-col>
</el-row>
</template>

<script>
import {getNewsList} from '../api/index';
// import {format} from '../util/common'; 
export default {
    name: 'NewsVue',
    data() {
        return {
            header:[],
            listNews:[],
			bool:false,
        }
    },
    methods:{ 
        format(shijianchuo) 
        { 
            shijianchuo = parseInt(shijianchuo)*1000;
            var time = new Date(shijianchuo); 
            var y = time.getFullYear(); 
            var m = time.getMonth()+1; 
                m = m < 10 ? "0" + m : m
            var d = time.getDate(); 
                d = d < 10 ? "0" + d : d
            var h = time.getHours(); 
                h = h < 10 ? "0" + h : h
            var mm = time.getMinutes(); 
                mm = mm < 10 ? "0" + mm : mm
            var s = time.getSeconds(); 
                s = s < 10 ? "0" + s : s
            return y+'-'+m+'-'+d+' '+h+':'+mm+':'+s; 
        }, 
        
    },
    created(){
        getNewsList({m_id:this.$store.getters.getMid}).then( res=>{
            this.listNews = res.data.result.data.data;
			console.log(this.listNews.length)
			if(this.listNews.length>0) this.bool = true
            // this.header = this.listNews.shift(); 
        }) 
    }
}
</script> 
<style scoped>
  
.main{
    width: 100%;
    min-height: 300px;
    background: #ffffff;
    margin: 0px auto; 
    padding-bottom: 100px;
}
.main .title{
    width: 100%;
    line-height: 30px;
    background: #467DEB;
    text-align: center;
    color:#ffffff;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    font-weight: bolder;
    font-size: 14px;
    margin-bottom: 30px;
}
.main .content{ 
    text-align: left;
    padding-bottom: 5px;
}

.content .header_title{
    width: 90%;
    margin: 0 auto;
    min-height:80px;
    border-bottom: solid 1px #DDDDDD;
    text-align: left; 
    padding-bottom: 12px;
}
.content .header_title h2{
   font-size: 14px;
   line-height: 22px;
}
.header_title p{
    line-height: 22px; 
    font-size: 12px;
}
.content ul li span{
    font-weight: bold;
}
.content ul li{
    width: 90%;
    margin: 0 auto;
    padding: 30px 30px 10px 30px;
    font-size: 14px;
    border-bottom: dashed 1px #DDDDDD;
    position: relative;
}
.content ul li .time{
    position: absolute;
    right: 0;
    top: 60px;
}
 
</style>


